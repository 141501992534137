import { graphql, useStaticQuery } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { filter, isEmpty, map, some } from 'lodash'
import React, { useEffect, useState } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import HeroForm from './HeroForm'
import './hero.less'

type Slide = {
  image: { childImageSharp: { fluid: { src: string } } }
  logo: string
  logo_class: string
}

const HeroContainer = () => {
  const { activeCityName } = useHomepage()
  const [slides, setSlides] = useState<Slide[]>([])
  const language = useI18next().language

  const data = useStaticQuery(graphql`
    {
      allHeroJson {
        edges {
          node {
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            logo
            logo_class
            city_name
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!isEmpty(activeCityName)) {
      const activeCityHasImages = some(
        data.allHeroJson.edges,
        (edge) => edge.node.city_name === activeCityName.toLowerCase(),
      )
      const city = activeCityHasImages ? activeCityName : 'new york city'
      const slides = map(
        filter(data.allHeroJson.edges, (edge) => edge.node.image && edge.node.city_name === city.toLowerCase()),
        (edge) => edge.node,
      )
      setSlides(slides)
    }
  }, [activeCityName])
  const mainSlide = slides.find((el) => el.logo === '/images/restaurants-logos/nyc/pizza.png')

  return (
    <div className="mealfix-hero-wrapper">
      <div className={`hero ${!slides.length && 'hero--empty'} hero--${language}`}>
        <div className="hero__image">
          <div>
            <div
              className="hero__carousel-image"
              style={{
                background: `url(${mainSlide?.image.childImageSharp.fluid.src}) no-repeat`,
              }}
            />
          </div>
        </div>
        <HeroForm />
      </div>
    </div>
  )
}

export default HeroContainer
