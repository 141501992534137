import { useI18next } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import Partners from '../mealpass-our-partners/Container'
import SEO from '../seo'
import HeroContainer from './hero/Container'

const MealfixContainer: React.FC<RouteProps<'/mealfix'>> = () => {
  const language = useI18next().language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [language])

  return (
    <>
      <SEO title="Mealfix" />
      <HeroContainer />
      <Partners />
    </>
  )
}

export default MealfixContainer
