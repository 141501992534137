import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import mealfixLogo from '../../../images/mealfix/mealfix-logo.png'
import Form from '../../form/Form'

const HeroForm = () => {
  const { cities } = useHomepage()
  const position = 'mealfix'

  const { t } = useTranslation()
  const language = useI18next().language

  useLayoutEffect(() => {
    window.scrollTo(2, 2)
  }, [])

  return (
    <div>
      <div className={`hero__form hero__form--${language}`}>
        <div>
          <span className="hero-title-mealfix">{t('Order food from top restaurants with')}</span>
          <br />
          <img src={mealfixLogo} alt="MealFix-Logo" className="mealfix__logo" />
        </div>
        <Form customClass="form--hero" position={position} cities={cities} />
      </div>
    </div>
  )
}

export default HeroForm
